// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import Label from '../../../components/Label';
import SvgIconStyle from '../../../components/SvgIconStyle';




const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  student: getIcon('ic_booking'),
};

// var navConfig = [];

// console.log("Nav Config Decode");

// console.log(decoded['cognito:groups']);

// if (decoded['cognito:groups'] !== undefined && (decoded['cognito:groups']).includes("ContentCreators")){

  const creatorNav = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
      subheader: 'general',
      items: [
        { title: 'Dashboard', path: PATH_DASHBOARD.content.root, icon: ICONS.booking },
      ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    {
      subheader: 'management',
      items: [
        // Question
        {
          title: 'Question Library',
          path: PATH_DASHBOARD.eCommerce.root,
          icon: ICONS.cart,
          children: [
            { title: 'questions', path: PATH_DASHBOARD.eCommerce.shop },
            { title: 'create', path: PATH_DASHBOARD.content.newQuestion},
            { title: 'upload', path: PATH_DASHBOARD.eCommerce.editById },
          ],
        },

        // Test Series
        {
          title: 'Test Series',
          path: PATH_DASHBOARD.eCommerce.root,
          icon: ICONS.cart,
          children: [
            { title: 'Test Series', path: PATH_DASHBOARD.eCommerce.shop },
            { title: 'create', path: PATH_DASHBOARD.eCommerce.newProduct },
          ],
        }

      ],
    }
  ];

 export default creatorNav;

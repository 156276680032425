import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// routes
import { PATH_DASHBOARD } from '../routes/paths';
import jwtDecode from 'jwt-decode';

import { isValidToken } from '../utils/jwt'


// ----------------------------------------------------------------------

GuestGuard.propTypes = {
  children: PropTypes.node
};

export default function GuestGuard({ children }) {
  const { isAuthenticated, session } = useAuth();
  
  //const token = session.getIdToken().getJwtToken();
  // console.log(token);

  // const decoded = jwtDecode(token);
  // console.log(decoded);



  if (isAuthenticated){

    const token = localStorage.getItem("sessionValues");
    const decoded =  token && isValidToken(token) ? jwtDecode(token) : "";
    console.log(decoded);



    if (decoded['cognito:groups'] != undefined && (decoded['cognito:groups']).includes("ContentCreators")){

      return <Navigate to={PATH_DASHBOARD.content.root} />;

    } else { 
      return <Navigate to={PATH_DASHBOARD.root} />;
    }
  
  }


  return <>{children}</>;
}
